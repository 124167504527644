import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { NavParams, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { BookingStatusVO, CourseVO, OrganisationVO, ServerAPIResponseVO, UserActivityVO } from 'src/app/valueObjects/core.vo';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import {Title} from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'page-activities',
  templateUrl: 'activities.html',
  styleUrls: ['activities.scss']
})

export class ActivitiesPage implements OnInit, OnDestroy, AfterViewInit {

  routeData: any = {};
  navigated: boolean = false;
  currentRoute: string = '';

  constructor(
    private copilot: NgxCopilotService,
    public router: Router,
    public navParams: NavParams,
    public appapi: Appapi,
    public httpClient: HttpClient,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public coursesService: CoursesService,
    private file: File,
    private walkthroughService: WalkthroughService,
    public redirectProvider: RedirectProvider,
    public uiux: UiuxService,
    private store: Store<appStore.AppViewState>,
    private nativeHTTP: HTTP,
    public platform: Platform,
    private fileOpener: FileOpener,
    private titleService:Title) {
      
      this.titleService.setTitle("My Activities");
   }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  component = ListItemComponent;
  @ViewChildren('animation') private animation: QueryList<ElementRef>;
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  fileTransfer: FileTransferObject;
  myParam: string;
  displayHelpIcon = true;
  isMobileView = this.uiux.isMobileView()
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  bookingStatusList$: BehaviorSubject<BookingStatusVO[]> = new BehaviorSubject([]);
  activeTab = 'upcoming';
  pageLabel = 'ActivitiesPage';
  userActivitiesList$: BehaviorSubject<UserActivityVO[]> = new BehaviorSubject([]);
  courseList$: BehaviorSubject<CourseVO[]> = new BehaviorSubject([]);
  animate: boolean = false;
  homeOrg: OrganisationVO;
  brandName$: Observable<string> = this.store.select(getBrandName);


  /** 
  * All activites and courses as ActivityListItem[].
  */
  activityListItems$: Observable<ActivityListItemVO[]> = combineLatest([
    this.userActivitiesList$,
    this.courseList$,
    this.bookingStatusList$,
  ]).pipe(takeUntil(this.destroy$), map(([activities, courses, bookingStatus]) => {

    // Set activities as ActivityListItem VO's.
    let activityList: ActivityListItemVO[] = []
    activities.forEach((userActivity: UserActivityVO) => {

      const activity = MyUtil.getActivity(userActivity.activity_id);
      
      if (activity) {
        //Work out if this activity clashes with anything the user has also booked
        let activityBookingClash = MyUtil.getBookingClashStatus(activity, bookingStatus);

        const userActivityVO: ActivityListItemVO = {
          id: activity.id,
          name: activity.name,
          oid: activity.oid,
          booking_type: activity.booking_type,
          provider: activity.provider,
          start_at: activity.start_at,
          end_at: activity.end_at,
          badge_details: activity.badge_details,
          price: activity.price,
          internal_reference: activity.internal_reference,
          homeOrg: this.homeOrg,
          code: activity.code,
          is_course: 0,
          period: activity.period,
          time_logged: userActivity?.time_logged,
          status: userActivity.status,
          venue: activity.venue,
          activity_template_id: activity.activity_template_id,
          attendance_type: activity.attendance_type,
          bookingStatus: activity.id in bookingStatus ? bookingStatus[activity.id] : null,
          bookingClash: activityBookingClash,
          added: true
        }
        activityList.push(userActivityVO)
      }
    })

    // Set courses as ActivityListItem VO's.
    let coursesList: ActivityListItemVO[] = [];
    for (const key in courses) {

      //Work out if any of the user's booked course activities clash with anything else the user has also booked
      let courseBookingClash = false;
      for(const courseActivityId in courses[key].course_activities) {
        courseBookingClash = MyUtil.getBookingClashStatus(courses[key].course_activities[courseActivityId], bookingStatus);
        if(courseBookingClash) {
          break;
        }
      }

      const userActivityVO: ActivityListItemVO = {
        id: courses[key].id,
        name: courses[key].name,
        oid: courses[key].oid,
        booking_type: courses[key].id,
        provider: courses[key].provider,
        start_at: courses[key].start_at,
        end_at: courses[key].end_at,
        badge_details: courses[key].badge_details,
        price: courses[key].price,
        internal_reference: courses[key].internal_reference,
        homeOrg: this.homeOrg,
        period: courses[key].period,
        code: courses[key].code,
        is_course: 1,
        is_hybrid: courses[key].is_hybrid == 1,
        course_complete: courses[key].course_complete,
        is_booked: courses[key].is_booked,
        on_waiting_list: courses[key].on_waiting_list,
        bookingClash: courseBookingClash,
        added: true,
        booking_opens_ts: courses[key].booking_opens_ts,
        booking_closes_ts: courses[key].booking_closes_ts,
        booking_opens_at: courses[key].booking_opens_at,
        booking_closes_at: courses[key].booking_closes_at,
        capacity: courses[key].capacity,
        online_capacity: courses[key].online_capacity,
        bookings: courses[key].bookings,
        onlineBookings: courses[key].online_bookings
      }
      coursesList.push(userActivityVO);
    }
    // Combine arrays.
    let activitiesAndCoursesList = [...activityList, ...coursesList];
    // Sort by ended at - Or change to what ever you want... (Sorts all lists).
    return activitiesAndCoursesList.sort((a, b) => a.name.localeCompare(b.name));
  }))

  /** 
  * Upcoming activities list.
  */
  upcomingActivitiesList$: Observable<ActivityListItemVO[]> = this.activityListItems$.pipe(map((activities: ActivityListItemVO[]) => {
    let activityList = activities.filter((userActivity: ActivityListItemVO) => {
      const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
      return  userActivity.status !== MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE 
              && userActivity.course_complete !== 1 
              && parseInt(userActivity.end_at) >= nowTimestamp;
    })
    return activityList.sort((a:any, b:any) => a.start_at - b.start_at);
  }))

  /** 
  * Past activities list.
  */
  pastActivitiesList$: Observable<ActivityListItemVO[]> = this.activityListItems$.pipe(map((activities: ActivityListItemVO[]) => {
    let activityList = activities.filter((userActivity: ActivityListItemVO) => {
      const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
      return ( userActivity.course_complete !== 1) && parseInt(userActivity.end_at) < nowTimestamp && (userActivity.status !== MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE);
    })
    return activityList.sort((a:any, b:any) => b.start_at - a.start_at);
  }))

  /** 
  * Complete activities list.
  */
  completeActivitiesList$: Observable<ActivityListItemVO[]> = this.activityListItems$.pipe(map((activities: ActivityListItemVO[]) => {
    let activityList = activities.filter((userActivity: ActivityListItemVO) => {
      return (userActivity.status === MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE) || userActivity.course_complete === 1 ;
    })
    return activityList.sort((a:any, b:any) => b.start_at - a.start_at);
  }))

  // After view init.
  ngAfterViewInit() {
    setTimeout(() => {
      this.uiux.setTransition(this.animation, [["all", 350, "normal"]], 200, 0);
      this.animate = true;
    }, 700);
    this.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }

  // On init
  async ngOnInit() {

    if(this.router.url == '/home') {
      //Redirect from the app.component page - redirect again to the default url
      this.router.navigate(['/']);
      return;
    }

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    // Sync and get activities and courses.
    await this.appapi.checkAndRefreshProfile().then(async () => {

      //Check for redirect
      this.redirectProvider.processRedirectTarget(this.router, null);

      this.navigated = true;
      this.homeOrg = MyUtil.getRootOrganization();
      let orgDetails = MyUtil.getAppOrg(this.homeOrg.id);
      let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);
      
      this.activityService.listBookingStatus().then((bookingStatusList: BookingStatusVO[]) => {
        this.bookingStatusList$.next(bookingStatusList);
      });

      await this.appapi.syncAllActivities().then(async () => {
        await this.coursesService.getUsersCourses().then((res: ServerAPIResponseVO) => {
          let courseList = [];
          if (res.data) {
            Object.keys(res.data).forEach((prop) => {

              // Object display setup.
              res.data[prop].period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
                res.data[prop].activityDateRange.start_date, 
                res.data[prop].start_time, 
                res.data[prop].activityDateRange.end_date, 
                res.data[prop].end_time)

              res.data[prop].start_at = res.data[prop].activityDateRange.start_date;
              res.data[prop].end_at = res.data[prop].activityDateRange.end_date;
              res.data[prop].attendance_type = res.data[prop].attendance_type;
              res.data[prop].courseFlag = true;

              courseList.push(res.data[prop]);
            });
          }
          this.courseList$.next(courseList);
        });

        await this.appapi.queryUserActivity().then((result: any[]) => {

          result.map((item, k) => {
            let activity = MyUtil.getActivity(item.activity_id);
            if (!activity) {
              return;
            }
            
            activity.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
              activity.start_at, 
              activity.start_time, 
              activity.end_at, 
              activity.end_time);
          });
          this.userActivitiesList$.next(result);
        })
      })
    }).then(async () => {
      this.loading$.next(false)
    });

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activities', data: this.navParams.data });

    // Check if profile has been completed.
    if (!MyUtil.validateProfile()) {
      this.router.navigate(['/UserSettingsPage', '{}']);
    }

    MyUtil.firebaseSetScreenName('activities');

    // Activate walkthrough if not disabled.
    this.startWalkthrough();
  }

  /** 
  * Set walkthrough state. 
  * @param pageName   Name of page.
  * @param value      Boolean - (Has been visited or not).
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value);
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition(stepNumber: string) {
    this.copilot.checkInit(stepNumber);
  }

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep(stepNumber: any) {
    try {
      this.copilot.next(stepNumber);
    } catch(err) {
      console.log(err);
    }
  }

  /** 
  * Finish copilot walkthroughs.
  */
  done() {
    this.copilot.removeWrapper();
  }

  // Start walkthrough.
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('1');
        // setTimeout(() => {
        //   document.getElementById('skip').focus();
        // }, 1000);
      }, 1000);
    }
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    switch (action) {

      // View activity list.
      case 'view-activity-list':
        this.router.navigate(['/ActivityListPage', '{}']);
        return;

      // View activity details.
      case 'view-activity-details':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;

      case 'view-course-details':
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id })]);
        return;

      // Create activity.
      case 'create-activity':
        if (!this.pageData.loading) {
          this.router.navigate(['/ActivityEditPage', JSON.stringify({})]);
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  // Export XLS or PDF version of activities list
  exportActivitiesSelection() {

    var buttons = [
      {
        text: 'Export XLS',
        handler: (data: any) => {
          let url = this.appapi.getActivityExport('export-activities', data);
          this.exportActivities(url, '.xlsx');
        }
      },
      {
        text: 'Export PDF',
        handler: (data: any) => {

          if (this.validateDates(data)) {
            let url = this.appapi.getActivityExportPDF('export-activities', data);
            this.exportActivities(url, '.pdf');
          }

        }
      }
    ];

    if (this.homeOrg.allow_pdf_transcript == 0) {
      buttons = [
        {
          text: 'Export XLS',
          handler: (data: any) => {
            let url = this.appapi.getActivityExport('export-activities', data);
            this.exportActivities(url, '.xlsx');
          }
        },
      ]
    }

    let orgDetails = MyUtil.getAppOrg(this.homeOrg.id);
    let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);

    let alert = MyUtil.presentAlert({
      title: 'Export Activities',
      message: "Please choose your date range (leave blank for 'all time') and export option.",
      inputs: [
        {
          type: 'date',
          name: 'date_from',
          label: 'Date From',
          placeholder: dateFormat
        },
        {
          type: 'date',
          name: 'date_to',
          label: 'Date To',
          placeholder: dateFormat
        }
      ],
      buttons: buttons
    });

  }


  validateDates(data) {

    let dateFrom = Date.parse(data.date_from);
    let dateTo = Date.parse(data.date_to);
    let today = Date.now();

    if (dateFrom > today || dateTo > today) {
      let alert = MyUtil.presentAlert({
        title: 'Error',
        message: "Dates should be before today's date",
        buttons: [
          {
            text: 'OK',
          }
        ]
      });

      return false;
    }

    if (dateTo < dateFrom) {
      let alert = MyUtil.presentAlert({
        title: 'Error',
        message: "Please choose a 'To Date' which is later than the 'From Date'!",
        buttons: [
          {
            text: 'OK',
          }
        ]
      });

      return false;
    }

    return true;

  }


  async exportActivities(url, type) {
    let loading = MyUtil.presentLoading();

    if (MyUtil.isMobileAppMode()) {

      var fileSpec = '';
      var fileName = '';
      if (type === '.pdf') {
        fileName = 'My-Activities.pdf';
        fileSpec = 'application/pdf';
      } else {
        fileName = 'My-Activities.xlsx';
        fileSpec = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      let filePath = (this.platform.is('ios')) ? this.file.documentsDirectory + fileName : this.file.dataDirectory + fileName;

      this.nativeHTTP.downloadFile(url, {}, {}, filePath).then((entry) => {
        MyUtil.debug('download complete: ' + filePath);

        this.fileOpener.open(filePath, fileSpec).then(
          async () => {
            (await loading).dismiss();
            console.log('File opened');
            MyUtil.debug('File opened');
          }, async (err) => {
            (await (loading)).dismiss();
            console.log('Failed to open: ' + err);
            MyUtil.debug('File to open');
            MyUtil.debug(JSON.stringify(err));
          }

        );
      }, async (error) => {
        (await loading).dismiss();
        MyUtil.debug(error);
      }
      );

    } else {
      // Browser download
      (await loading).dismiss();
      console.log('url', url)
      this.httpClient.get(url, {
        responseType: "blob"
      }).subscribe(
        (res) => {
          console.log('HTTP response', res)
          console.log('data', res)
          var fileURL = window.URL.createObjectURL(res);
          let tab = window.open();
          tab.location.href = fileURL;
        },
        (err) => {
          console.log('HTTP Error', err)
        },
        () => console.log('HTTP request completed.')
      )
    }
  }


  /** 
  * For mobile view tabs.
  * @param label   Label of tab.
  */
  changeTab(label: string) {
    this.activeTab = label;
  }

  /** 
  * Click handler for list items.
  * @param item   Item of list item clicked (VO of item).
  */
  clickHandler(item) {
    return !item.is_course ? this.process('view-activity-details', item) : this.process('view-course-details', item);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'about', data: this.navParams.data });
  }
}
